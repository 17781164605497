@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

* {
  box-sizing: border-box;
}
body {
  background: #22254b;
  color: #fff;
  cursor: default;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

header {
  background-color: #373b69;
  display: flex;
  padding: 1rem;
  justify-content: flex-end;
}

.logo {
  background: url("https://www.netclipart.com/pp/f/128-1288073_watch-clipart-watch-movie-movie-icon-circle-png.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  margin: 0 2rem;
  align-items: center;
}

.search-btn {
  color: #fff;
  font-family: inherit;
  font-size: 1.2rem;
  border: 2px solid #22254b;
  border-radius: 50px;
  background-color: transparent;
  padding: 0.5rem 1.5rem;
  transition: 0.2s ease-in-out;
}

.search-btn:focus {
  border: 2px solid #fff;
}

.movie-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.movie {
  background-color: #373b69;
  border-radius: 3px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1 green, blue, alpha);
  margin: 1rem;
  overflow: hidden;
  width: 300px;
  position: relative;
}

.movie img {
  max-width: 100%;
}

.movie .movie-info {
  display: flex;
  padding: 1.3rem;
  align-items: center;
  justify-content: space-between;
}

.movie .movie-info h3 {
  margin: 0;
}

.movie .movie-date {
  font-size: 12px;
  padding-left: 0.5rem;
}

.movie .movie-summary {
  background-color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1 green, blue, alpha);
  color: #22254b;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  overflow: auto;
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
}

.movie .movie-summary h2 {
  font-size: 16px;
}

.movie:hover .movie-summary {
  transform: translateX(0%);
}

.movie .movie-star {
  width: 1rem;
}

.movie .movie-info .movie-info-bar {
  display: flex;
  gap: 0.25rem;
}

.footer {
  background-color: #373b69;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.footer .footer-link {
  color: #fff;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  text-decoration: none;

  &:hover {
    color: #d3d3d3;
  }
}
